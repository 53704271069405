import styled from 'styled-components';

const FeatureSectionWrapper = styled.section`
  padding: 80px 0 120px 0;
  background: #fcfcfc;
  @media (max-width: 990px) {
    padding: 60px 0 60px 0;
  }
  @media (max-width: 767px) {
    padding: 60px 0 30px 0;
  }
  ${
    '' /* .row {
    margin-right: -40px;
    @media (max-width: 1440px) {
      margin-right: -30px;
    }
    @media (max-width: 768px) {
      margin-right: -15px;
    }
    @media (max-width: 480px) {
      margin-right: 0px;
    }
  } */
  }
  .col {
    overflow: hidden;
    position: relative;
    width: calc(100% / 2 - 24px);
    margin: 0 12px;
    ${'' /* margin-right: 40px; */}
    ${'' /* width: calc(100% / 2 - 40px); */}
    ${'' /* &:last-child {
      margin-right: 0px;
    } */}
    ${
      '' /* @media (max-width: 1440px) {
      margin-right: 30px;
      width: calc(100% / 2 - 30px);
    }
    @media (max-width: 768px) {
      margin-right: 15px;
      width: calc(100% / 2 - 15px);
    }
    @media (max-width: 480px) {
      margin-right: 0px;
      width: 100%;
      margin-bottom: 15px;
    } */
    }
    @media only screen and (max-width: 991px) {
      width: calc(100% / 2 - 24px);
      margin-bottom: 24px;
      &:first-child {
        width: 100%;
      }
    }
    @media only screen and (max-width: 767px) {
      width: calc(100% - 24px);
    }
  }
  .feature__block {
    ${'' /* display: flex; */}
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
  .project__post {
    padding-bottom: 20px;
    .content__wrapper {
      margin-top: 20px;
    }
    button {
      background: transparent;
      ${'' /* border: 0; */}
      display: block;
      box-shadow: 0;
      padding: 0;
      margin: 0;
      margin-right: 5px;
      > span {
        background: transparent;
        padding: 0;
        margin: 0;
      }
    }
  }
`;

export const NextButton = styled.div`
  position: relative;
  cursor: pointer;
  .next_btn__wrapper {
    display: flex;
    align-items: center;
  }
  &:hover {
    span {
      background: ${({ theme }) => theme.colors.link};
      @media (min-width: 991px) {
        width: 25px;
      }
    }
  }
  span {
    width: 15px;
    height: 2px;
    background: ${({ theme }) => theme.colors.link};
    position: relative;
    transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    &:before,
    &:after {
      content: '';
      display: block;
      height: 2px;
      border-radius: 2px;
      background: inherit;
      position: absolute;
    }
    &:before {
      transform: rotate(45deg);
      top: -4px;
      right: 0;
      width: 10px;
    }
    &:after {
      transform: rotate(-45deg);
      width: 8px;
      bottom: -6px;
      right: 1px;
    }
  }
`;

export default FeatureSectionWrapper;
