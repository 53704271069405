import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Button from 'reusecore/src/elements/Button';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from '../../components/FeatureBlock';

import Container from '../../components/UI/Container';
import FeatureSectionWrapper, { NextButton } from './latest.style';

const FeatureSection = ({
  projects,
  row,
  col,
  featureTitle,
  featureDescription,
  iconStyle,
  contentStyle,
  btnStyle
}) => {
  return (
    <FeatureSectionWrapper id="project_section">
      <Container noGutter mobileGutter className="container">
        <Box className="row" {...row}>
          {projects.map(({ node }) => {
            const { frontmatter: { title, thumbnail, description, slug } } = node;
            return (
              <Box className="col" {...col} key={`${slug}`}>
                <FeatureBlock
                  icon={<img src={thumbnail} alt={title} />}
                  iconStyle={iconStyle}
                  contentStyle={contentStyle}
                  title={<Link to={`/proyecto/${slug}`}><Heading content={title} {...featureTitle} /></Link>}
                  description={<Text content={description} {...featureDescription} />}
                  button={(
                    <Link to={`/proyecto/${slug}`}>
                      <NextButton>
                        <div className="next_btn__wrapper">
                          <Button title="Ver Proyecto" {...btnStyle} />
                          <span />
                        </div>
                      </NextButton>
                    </Link>
)}
                  className="project__post"
                />
              </Box>
            );
          })}
        </Box>
      </Container>
    </FeatureSectionWrapper>
  );
};

// FeatureSection style props
FeatureSection.propTypes = {
  projects: PropTypes.array,
  row: PropTypes.object,
  col: PropTypes.object,
  contentStyle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
  btnStyle: PropTypes.object
};

// FeatureSection default style
FeatureSection.defaultProps = {
  projects: [],
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-12px',
    mr: '-12px'
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 2]
  },
  // feature content default style
  contentStyle: {
    textAlign: 'left',
    mt: ['30px', '30px']
  },
  // feature title default style
  featureTitle: {
    fontSize: ['15px', '16px'],
    lineHeight: '1.5',
    fontWeight: '600',
    color: 'purple',
    textAlign: 'left',
    fontFamily: 'Poppins',
    mb: '0px'
  },
  // feature description default style
  featureDescription: {
    lineHeight: ['28px', '32px', '32px', '32px', '32px'],
    mt: ['7px', '7px', '7px', '7px', '7px'],
    mb: ['7px', '7px', '7px', '7px', '7px'],
    textAlign: ['left', 'left'],
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: 'purple',
    fontFamily: 'Poppins'
  },
  btnStyle: {
    lineHeight: ['28px', '32px', '32px', '32px', '32px'],
    fontSize: ['15px', '16px'],
    fontWeight: '700',
    color: 'link',
    fontFamily: 'Poppins'
  }
};

export default FeatureSection;
